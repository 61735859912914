
(function () {

  const d = document;
  const forEach = [].forEach;
  const targ = d.getElementById('vid');

  function parseURL (url) {
    const parser = d.createElement('a');
    const searchObject = {};
    // let queries;
    let _split;
    parser.href = url;
    // Convert query string to object
    const queries = parser.search.replace(/^\?/, '').split('&');
    forEach.call(queries, query => {
      _split = query.split('=');
      searchObject[_split[0]] = _split[1];
    });
    return {
      protocol: parser.protocol,
      host: parser.host,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      searchObject: searchObject,
      hash: parser.hash,
      uri: url
    };
  }

  const addBttnListener = el => {
    el.addEventListener('click', () => {
      /*
      e.preventDefault()
      */
      clearCurrent();
      el.parentNode.classList.add('cur');
      insertVid(el);
      // targ.scrollIntoView(true);
      targ.focus;
    });
  };

  const clearCurrent = () => {
    const figs = d.querySelectorAll('figure');
    forEach.call(figs, function (e) {
      if (e.classList.contains('cur')) {
        e.classList.remove('cur');
      }
    });
  };

  const insertVid = el => {
    if (el !== undefined) {
      let ifrm;
      const vidURL = el.dataset.video;
      const parsedURL = parseURL(vidURL);
      const vId = parsedURL.searchObject['v'];
      // var oput = vId ? '<iframe width="560" height="315" src="//www.youtube.com/embed/' + vId + '/?autoplay=1&rel=0&amp;showinfo=0" frameborder="0" allowfullscreen></iframe>' : ''
      if (vId) {
        targ.innerHTML = '';
        ifrm = document.createElement('IFRAME');
        ifrm.width = 560;
        ifrm.height = 315;
        ifrm.title = 'The Marine Exchange of California';
        ifrm.src = 'https://www.youtube-nocookie.com/embed/' + vId + '/?autoplay=1&rel=0&showinfo=0';
        // ifrm.srcdoc = '<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/' + vId + '/?autoplay=1&rel=0&showinfo=0> <img src=https://img.youtube.com/vi/' + vId + '/sddefault.jpg loading=lazy alt=\'Captain Kip Louttit of The Marine Exchange of Southern California\'><span>▶</span></a>';
        ifrm.setAttribute('allow', 'autoplay, encrypted-media, gyroscope, picture-in-picture');
        // ifrm.setAttribute('frameborder', '0')
        ifrm.setAttribute('allowfullscreen', 'allowfullscreen');
        targ.classList.add('play');
        targ.appendChild(ifrm);
        targ.scrollIntoView(true);
      }
      /*
      targ.innerHTML = ''
      targ.classList.add('play')
      targ.innerHTML = oput
      */
    }
  };

  const bttnHndlr = () => {
    const bttns = d.querySelectorAll('.j-vo');
    forEach.call(bttns, function (el) {
      addBttnListener(el);
    });
  };

  const checkAuto = () => {
    if (targ && targ.classList.contains('auto')) {
      const bttn = targ.querySelector('.j-vo');
      insertVid(bttn);
    }
  };

  bttnHndlr();
  checkAuto();
}())
